import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Vignette } from "../components/vignette"
import { Avantages, Spacer, Newsletter } from "../components/banners"
import Insta from "../components/insta"

const prod = (catalog) => (
  catalog.edges[Math.floor(Math.random()*9)].node
)

export default ({ data }) => {
  const catalog = data.allMongodbHerokuD589Dff8Mbmjproducts

  return (
    <Layout>
      <Avantages />
      <Spacer v="5rem"></Spacer>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Vignette size="large" p={prod(catalog)}></Vignette>
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              fontSize: "1.1rem",
              maxWidth: "585px",
              textAlign: "center",
              padding: "60px 40px",
            }}
          >
            <h2 style={{ fontSize: "1.6rem", fontStyle: "italic" }}>
              Collection Printemps-Été 2020
            </h2>

            <p style={{ letterSpacing: "0.15rem" }}>
              Nos vêtements sont des essentiels de personnalité. <br />
              Ils prennent vie entre vos mains. <br />
              Que vous soyez fashionista, bohème, boyish, preppy ou rien de tout
              ça : <br />
              façonnez-les, sublimez-les, <br />
              détournez-les, vivez ! <br />
              <br />
              Tous nos modèles sont confectionnés à Paris, dans de jolies
              matières naturelles.
            </p>
          </div>
          <Vignette size="large" p={prod(catalog)}></Vignette>
        </div>
      </div>

      <Spacer v="2rem"></Spacer>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Vignette p={prod(catalog)}></Vignette>
        <Vignette p={prod(catalog)}></Vignette>
        <Vignette p={prod(catalog)}></Vignette>
      </div>

      <Spacer v="2rem"></Spacer>

      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Vignette size="huge" p={prod(catalog)}></Vignette>
      </div>

      <Spacer v="2rem"></Spacer>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Vignette size="large" p={prod(catalog)}></Vignette>
        <Vignette size="large" p={prod(catalog)}></Vignette>
      </div>

      <Spacer v="2rem"></Spacer>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Vignette size="large" p={prod(catalog)}></Vignette>
        <Vignette size="medium" p={prod(catalog)}></Vignette>
      </div>

      <Spacer v="2rem"></Spacer>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Vignette p={prod(catalog)}></Vignette>
        <Vignette p={prod(catalog)}></Vignette>
        <Vignette p={prod(catalog)}></Vignette>
      </div>

      <Spacer v="2rem"></Spacer>

      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Vignette size="huge" p={prod(catalog)}></Vignette>
      </div>

      <Spacer v="2rem"></Spacer>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Vignette size="large" p={prod(catalog)}></Vignette>
        <Vignette size="large" p={prod(catalog)}></Vignette>
      </div>

      <Spacer v="6rem"></Spacer>

      <Avantages />

      <Spacer v="6rem"></Spacer>

      <Newsletter />

      <Spacer v="5rem" />
      <Insta />
    </Layout>
  )
}

export const query = graphql`
  query {
    allMongodbHerokuD589Dff8Mbmjproducts {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
  }
`
